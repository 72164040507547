import { useEffect, useState } from 'react';
import {
	useRecordContext,
	ListContextProvider,
	Pagination,
	useList,
	Datagrid,
	TextField,
	FunctionField,
	useNotify
} from 'react-admin';

import { Schema } from '../../../../../types';
import { formatMonthStamp } from '../../../../../lib';
export const FlexScheduleSubTab: React.FC = () => {
	type Data = {
		id: number;
		monthStamp: string;
		newCount: number;
		returningCount: number;
	};
	const record = useRecordContext() as Schema.ClientAuth | undefined;

	const [data, setData] = useState<Data[]>([]);
	const [loading, setLoading] = useState(true);

	const notify = useNotify();

	const listContext = useList({
		data,
		isLoading: loading,
		perPage: 25,
		sort: { field: 'monthStamp', order: 'DESC' }
	});

	useEffect(() => {
		if (!record) {
			return;
		}
		(async () => {
			try {
				const res = await fetch(
					'/secure/admin/flexScheduleEngagementReport',
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							client_identifier: record.client_identifier
						})
					}
				);

				if (!res.ok) {
					throw new Error(`HTTP error! status: ${res.status}`);
				}

				const data = await res.json();
				setData(data);
			} catch (err) {
				notify(
					`Error fetching flex schedule data. This is likely due to a
					missing create_date on the clientAuth record`,
					{ type: 'error' }
				);
				setData([]);
			} finally {
				setLoading(false);
			}
		})();
	}, [record, notify]);

	if (!loading && data.length === 0) {
		return <div>No FS data available</div>;
	}

	return (
		<ListContextProvider value={listContext}>
			<div style={{ width: '100%' }}>
				<Datagrid bulkActionButtons={false} hover={false}>
					<FunctionField
						source="monthStamp"
						label="Month"
						render={(record: Data) =>
							formatMonthStamp(record.monthStamp)
						}
					/>
					<TextField
						source="newCount"
						textAlign="center"
						label="New"
					/>
					<TextField
						source="returningCount"
						textAlign="center"
						label="Returning"
					/>
				</Datagrid>
				<Pagination />
			</div>
		</ListContextProvider>
	);
};
