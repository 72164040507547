import {
	Datagrid,
	List,
	Pagination,
	ReferenceArrayField,
	TextField,
	SingleFieldList,
	ChipField,
	FunctionField,
	useGetMany,
	useList,
	ListContextProvider,
	TopToolbar,
	CreateButton,
	TextInput
} from 'react-admin';
import { useHasPermission } from '../../lib';

type ClientsFieldProps = {
	ids: number[];
};

const ClientsField: React.FC<ClientsFieldProps> = ({ ids }) => {
	const idsListContext = useList({
		data: ids.map(id => ({ id })),
		perPage: 10,
		page: 1
	});

	const res = useGetMany(
		'ClientAuth',
		{
			ids: idsListContext.data.map(({ id }) => id)
		},
		{
			enabled: idsListContext.data.length <= 10
		}
	);

	const dataListContext = useList({
		...res,
		isLoading: res.isLoading || idsListContext.data.length > 10,
		error: res.error
	});

	return (
		<ListContextProvider value={dataListContext}>
			<SingleFieldList>
				<ChipField source="client.practice_name" />
			</SingleFieldList>

			<ListContextProvider value={idsListContext}>
				<Pagination perPage={10} rowsPerPageOptions={[10]} />
			</ListContextProvider>
		</ListContextProvider>
	);
};

const FeatureFlagListActions: React.FC = () => {
	const { hasPermission } = useHasPermission();

	return (
		<TopToolbar>
			{hasPermission('Create Feature Flag') && <CreateButton />}
		</TopToolbar>
	);
};

export const FeatureFlagList: React.FC = () => {
	return (
		<List
			filters={[
				<TextInput
					label="Search Feature Flags"
					source="featureFlag_search"
					alwaysOn
				/>
			]}
			actions={<FeatureFlagListActions />}
		>
			<Datagrid rowClick="show">
				<TextField source="id" />
				<TextField source="description" />
				<TextField source="gaVersion" />

				<FunctionField
					label="Clients"
					render={(record: { clients_ids: number[] }) => (
						<ClientsField ids={record.clients_ids} />
					)}
				/>

				<ReferenceArrayField
					reference="ClientGroup"
					source="clientGroups_ids"
				/>
			</Datagrid>
		</List>
	);
};
