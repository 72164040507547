import React, { useState } from 'react';
import {
	EditBase,
	SimpleForm,
	Title,
	Toolbar,
	SaveButton,
	useGetRecordId,
	useRecordContext,
	useSaveContext
} from 'react-admin';
import { Card, Grid, Button, Tabs, Tab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { ClientAuthSummaryTab } from './components/ClientAuthSummaryTab';
import { ClientAuthConfigTab } from './components/ClientAuthConfigTab';
import { ClientAuthLogTab } from './components/ClientAuthLogTab';
import { ClientAuthSideBar } from './components/ClientAuthSideBar';
import { ClientAuthUsageTab } from './components/ClientAuthUsageTab';
import { Schema } from '../../types';

interface ClientAuthEditToolbarProps {
	editMode: boolean;
	toggleEditMode: () => void;
	handleSaveClick: () => void;
	handleCancelClick: () => void;
}

export const ClientAuthEditToolbar: React.FC<ClientAuthEditToolbarProps> = ({
	editMode,
	toggleEditMode,
	handleSaveClick,
	handleCancelClick
}) => {
	return (
		<Toolbar
			sx={{
				justifyContent: 'center',
				zIndex: 999,
				background: 'transparent',
				position: 'fixed',
				bottom: 0,
				right: '2rem',
				width: 'fit-content'
			}}
		>
			{editMode ? (
				<>
					<SaveButton
						type="button"
						label="Save"
						size="large"
						onClick={handleSaveClick}
					/>
					<Button
						variant="outlined"
						color="secondary"
						size="large"
						onClick={handleCancelClick}
						sx={{ marginLeft: 1 }}
					>
						Cancel
					</Button>
				</>
			) : (
				<Button
					variant="contained"
					color="primary"
					size="large"
					onClick={toggleEditMode}
					startIcon={<EditIcon />}
				>
					Edit
				</Button>
			)}
		</Toolbar>
	);
};

const ClientAuthEditTitle = () => {
	const id = useGetRecordId();
	const record = useRecordContext() as Schema.ClientAuth | undefined;
	return <Title title={String(record?.client.practice_name ?? id)} />;
};

interface TabConfig {
	label: string;
	content: React.ReactNode;
}

export const ClientAuthEdit: React.FC = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [editMode, setEditMode] = useState(false);
	const saveContext = useSaveContext();
	const toggleEditMode = () => {
		setEditMode(prev => !prev);
	};

	const handleFormSubmit = async (values: Partial<Schema.ClientAuth>) => {
		if (!saveContext) {
			console.error('Save context not found');
			setEditMode(false);
			return;
		}
		saveContext.save?.(values as Schema.ClientAuth);
		setEditMode(false);
	};

	const handleCancelClick = () => {
		toggleEditMode();
	};

	const tabs: TabConfig[] = [
		{
			label: 'Summary',
			content: (
				<SimpleForm
					onSubmit={handleFormSubmit}
					toolbar={
						<ClientAuthEditToolbar
							editMode={editMode}
							toggleEditMode={toggleEditMode}
							handleSaveClick={() => {
								document
									.querySelector<HTMLFormElement>('form')
									?.requestSubmit();
							}}
							handleCancelClick={handleCancelClick}
						/>
					}
				>
					<ClientAuthSummaryTab editMode={editMode} />
				</SimpleForm>
			)
		},
		{
			label: 'Config',
			content: <ClientAuthConfigTab />
		},
		{
			label: 'Out Log',
			content: <ClientAuthLogTab type="out" />
		},
		{
			label: 'Error Log',
			content: <ClientAuthLogTab type="err" />
		},
		{
			label: 'Usage',
			content: <ClientAuthUsageTab />
		}
	];

	const handleTabChange = (
		_event: React.SyntheticEvent,
		newValue: number
	) => {
		setActiveTab(newValue);
	};

	return (
		<EditBase
			sx={{ '& .RaEdit-main': { mt: 0 } }}
			redirect={false}
			transform={(
				data: Schema.ClientAuth,
				{ previousData } = { previousData: {} }
			) => {
				if (!data.liineCustomerMapping?.liine_customer_id) {
					if (previousData?.liineCustomerMapping?.liine_customer_id) {
						alert('Cannot delete liine customer mapping');
						return {
							...data,
							liineCustomerMapping:
								previousData.liineCustomerMapping
						};
					}
					data.liineCustomerMapping = null;
				}

				return { ...data };
			}}
		>
			<ClientAuthEditTitle />

			<Grid container spacing={2}>
				<Grid item xs={12} md={8} xl={9} order={{ xs: 1, md: 0 }}>
					<Card>
						<Tabs value={activeTab} onChange={handleTabChange}>
							{tabs.map((tab, index) => (
								<Tab key={index} label={tab.label} />
							))}
						</Tabs>

						{tabs[activeTab].content}
					</Card>
				</Grid>

				<Grid item xs={12} md={4} xl={3} order={{ xs: 0, md: 1 }}>
					<ClientAuthSideBar />
				</Grid>
			</Grid>
		</EditBase>
	);
};
