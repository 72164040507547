import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import { FlexScheduleSubTab } from './ClientAuthUsageSubTabs/FlexScheduleSubTab';

export const ClientAuthUsageTab: React.FC = () => {
	const [activeTab, setActiveTab] = useState('flex-schedule');

	const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
		setActiveTab(newValue);
	};

	return (
		<Box
			sx={{
				width: '100%'
			}}
		>
			<TabContext value={activeTab}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList
						onChange={handleChange}
						aria-label="client auth usage tabs"
						sx={{
							minHeight: '36px',
							'& .MuiTab-root': {
								minHeight: '36px',
								padding: '6px 12px'
							}
						}}
					>
						<Tab label="Flex Schedule" value="flex-schedule" />
					</TabList>
				</Box>
				<TabPanel value="flex-schedule">
					<FlexScheduleSubTab />
				</TabPanel>
			</TabContext>
		</Box>
	);
};
