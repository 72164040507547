import { Schema } from '../../../types';

export const featureFlagSearch = (
	value?: string
): Schema.FeatureFlagWhereInput => {
	if (!value) return {};
	value = value.trim();

	if (value === '') return {};

	const filters: Schema.FeatureFlagWhereInput['OR'] = [
		{
			id: { contains: value }
		},
		{
			description: { contains: value }
		}
	];

	return { OR: filters };
};
