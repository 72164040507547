import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FlexPackage } from '../../../../../../generated/graphql/types/schema';

interface FlexPackageItem {
	name: string;
	value: FlexPackage;
}

export const flexPackages: FlexPackageItem[] = [
	{
		name: 'FlexEssentials',
		value: 'FLEX'
	},
	{
		name: 'FlexGrowth',
		value: 'FLEX_FLEXSCHEDULE'
	},
	{
		name: 'FlexUltimate',
		value: 'FLEXULTIMATE'
	},
	{
		name: 'Flex+FlexVerification Core',
		value: 'FLEX_FLEXVERIFICATION'
	},
	{
		name: 'FlexPremier',
		value: 'FLEXPREMIER'
	},
	{
		name: 'FlexSchedule',
		value: 'FLEXSCHEDULE'
	}
];

interface FlexPackageTypeSelectProps {
	disabled?: boolean;
	defaultValue?: FlexPackage;
	onBlur?: () => void;
	onChange?: (selectedFlexPackage: FlexPackage) => void;
}

export const FlexPackageTypeSelect: React.FC<
	FlexPackageTypeSelectProps
> = props => {
	const handleChange = (event: SelectChangeEvent<FlexPackage>) => {
		props.onChange?.(event.target.value as FlexPackage);
	};

	return (
		<FormControl
			fullWidth
			disabled={props.disabled}
			required
			variant="outlined"
		>
			<InputLabel id="flex-package-select-label">Flex Package</InputLabel>
			<Select
				labelId="flex-package-select-label"
				defaultValue={props.defaultValue ?? 'FLEX'}
				label="Flex Package"
				onBlur={props.onBlur}
				onChange={handleChange}
			>
				{flexPackages.map(flexPackage => (
					<MenuItem value={flexPackage.value}>
						{flexPackage.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default FlexPackageTypeSelect;
